import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { LayoutReducer } from '../modules/layout/state/Layout.reducer';
import { MainReducer } from '../modules/main/state/Main.reducer';

const createStore = (): EnhancedStore => {
  return configureStore({
    reducer: {
      layout: LayoutReducer,
      main: MainReducer
    },
    middleware: [thunkMiddleware]
  });
};

const Store: EnhancedStore = createStore();

export type AppDispatch = typeof Store.dispatch;

export type RootState = ReturnType<typeof Store.getState>;

export default createStore;

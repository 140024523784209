import {
  ToggleGameSwitcher,
  ToggleHomeMenu,
  ToggleMenu,
  SetGameHover,
  ToggleSideBar,
  ToggleShowConfiguration,
  ToggleCardModeAg
} from '../actions/Layout.actions';
import { InitialLayoutState } from './Layout.state';
import { createReducer } from '@reduxjs/toolkit';

export const LayoutReducer = createReducer(InitialLayoutState, (builder) => {
  builder
    .addCase(ToggleSideBar, (state) => {
      state.isSideMenuExtended = !state.isSideMenuExtended;
    })
    .addCase(ToggleMenu, (state) => {
      state.isMenuVisible = !state.isMenuVisible;
    })
    .addCase(ToggleHomeMenu, (state) => {
      state.isHomeMenuVisible = !state.isHomeMenuVisible;
    })
    .addCase(ToggleGameSwitcher, (state) => {
      state.isGameSwitcherVisible = !state.isGameSwitcherVisible;
    })
    .addCase(SetGameHover, (state, { payload }) => {
      state.gameSwitcherHover = payload;
    })
    .addCase(ToggleShowConfiguration, (state) => {
      state.showConfiguration = !state.showConfiguration;
    })
    .addCase(ToggleCardModeAg, (state) => {
      state.toggleCardModeAg = !state.toggleCardModeAg;
    });
});

import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

import './src/styles/site-global.scss';

config.autoAddCss = false;

const onRedirectCallback = (appState: AppState | undefined) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};

interface IProps {
  element: React.ReactNode;
}

export const wrapRootElement: React.FC<IProps> = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN || ''}
      clientId={process.env.AUTH0_CLIENTID || ''}
      redirectUri={process.env.AUTH0_CALLBACK || ''}
      audience={process.env.AUTH_AUDIENCE || ''}
      scope={process.env.AUTH_SCOPE || ''}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  );
};

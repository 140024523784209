import { createAction } from '@reduxjs/toolkit';

export const ToggleSideBar = createAction('layout/toggleSideBar');
export const ToggleMenu = createAction('layout/toggleMenu');
export const ToggleHomeMenu = createAction('layout/toggleHomeMenu');
export const ToggleGameSwitcher = createAction('layout/toggleGameSwitcher');
export const SetGameHover = createAction<string>('layout/setGameHover');
export const ToggleCardModeAg = createAction<string>('layout/toggleCardModeAg');
export const ToggleShowConfiguration = createAction(
  'layout/toggleShowConfiguration'
);

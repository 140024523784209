export interface ILayoutState {
  isSideMenuExtended: boolean;
  isMenuVisible: boolean;
  isHomeMenuVisible: boolean;
  isGameSwitcherVisible: boolean;
  gameSwitcherHover: string;
  showConfiguration: boolean;
  toggleCardModeAg: boolean;
}

export const InitialLayoutState: ILayoutState = {
  isSideMenuExtended: true,
  isMenuVisible: false,
  isHomeMenuVisible: false,
  isGameSwitcherVisible: false,
  gameSwitcherHover: 'reverse',
  showConfiguration: false,
  toggleCardModeAg: true
};
